export const menuItems = [
  {
    id: 1,
    label: "Master Kompetensi",
    subItems: [
      {
        id: 2,
        label: "Jabatan Kompetensi",
        link: "/master-kompetensi/jabatan-kompetensi",
        parentId: 1,
      },
      {
        id: 3,
        label: "Kategori Studi Kasus",
        link: "/master-kompetensi/kategori-studi-kasus",
        parentId: 1,
      },
      {
        id: 4,
        label: "Kompetensi",
        link: "/master-kompetensi/kompetensi",
        parentId: 1,
      },
      {
        id: 5,
        label: "Konfigurasi Timer",
        link: "/master/timer",
        parentId: 1,
      },
      {
        id: 6,
        label: "Treshold Nilai",
        link: "/master/treshold-nilai",
        parentId: 1,
      },
      {
        id: 18,
        label: "Pelaksanaan",
        link: "/master/pelaksanaan",
        parentId: 1,
      },
    ],
  },
  {
    id: 10,
    label: "Jadwal Pelaksanaan",
    link: "/master-penjadwalan/jadwal-pelaksanaan",
    parentId: 1,
  },
  {
    id: 15,
    label: "Report Asesmen",
    subItems: [
      {
        id: 16,
        label: "Report Jadwal",
        link: "/report-jadwal",
        parentId: 1,
      },
      {
        id: 11,
        label: "Report Nilai Kompetensi",
        link: "/report-nilai-kompetensi",
        parentId: 1,
      },
      {
        id: 11,
        label: "Report Nilai Jabatan",
        link: "/report-nilai-jabatan",
        parentId: 1,
      },
      {
        id: 12,
        label: "Report Hasil",
        link: "/report-hasil",
        parentId: 1,
      },
    ],
  },
  {
    id: 13,
    label: "Log Peserta",
    link: "/log-peserta",
    parentId: 1,
  },
  {
    id: 14,
    label: "File Manager",
    link: "/file-manager",
    parentId: 1,
  },
  {
    id: 14,
    label: "Informasi Ujian",
    link: "/info-ujian",
    parentId: 1,
  },
  {
    id: 19,
    label: "Manajemen Hak Akses",
    subItems: [
      {
        id: 20,
        label: "Role User",
        link: "/master/role",
        parentId: 19,
      },
      {
        id: 21,
        label: "User",
        link: "/master/user",
        parentId: 19,
      },
    ],
  },
];
